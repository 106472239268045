/* --------------------------------------------------------------------------
  Enables the native <details> element to auto-close when users click
  outside of it. Works by removing the `open` attribute.

  NOTE: I have not tested how performant this `stimulus-use` library is.
  Could potentially be a performance issue with lots of such <details>.
-------------------------------------------------------------------------- */
import { Controller } from '@hotwired/stimulus'

// HEXIS FRONTEND
// import $console from '@hexis-hr/utils/console'


// NOT lazy-loaded because the main navigation uses this controller
export default class extends Controller<HTMLElement> {

  // LOCAL STATE
  private disposeHoverIntent: () => void


  /* --------------------------------------------------------------------------
    CONTROLLER LIFECYCLE HOOKS
    Try to keep this short and clean it should just "tell a story".
    Most of the code should be written in meaningfully named methods below.
  -------------------------------------------------------------------------- */

  // (1) Once, when the controller is first instantiated
  initialize() { }

  // (2) Anytime the controller is connected to the DOM
  connect() {
    this.disposeHoverIntent = hoverIntent(
      this.element,
      this.open,
      this.close,
    )
  }

  // (3) Anytime the controller is disconnected from the DOM
  disconnect() {
    this.disposeHoverIntent()
  }


  /* --------------------------------------------------------------------------
    EVENTS
  -------------------------------------------------------------------------- */
  open = () => {
    this.element.setAttribute('open', '')
  }

  close = () => {
    this.element.removeAttribute('open')
  }


}


// TODO: Move to `frontend` repo
function hoverIntent (
  element: HTMLElement, // element to watch for hover events
  onEnter: (e: MouseEvent) => void, // callback for `mouseenter` event
  onLeave: (e: MouseEvent) => void, // callback for `mouseleave` event
  delay = 80, // used for both `enter` & `leave` delays (by default)
  leaveDelay?: number, // optional override for the `leave` delay
): () => void {
  let enterTimer: number | null = null
  let leaveTimer: number | null = null

  const handleMouseEnter = (e: MouseEvent) => {
    if (leaveTimer) {
      clearTimeout(leaveTimer)
      leaveTimer = null
    }
    enterTimer = window.setTimeout(() => {
      onEnter(e)
    }, delay)
  }

  const handleMouseLeave = (e: MouseEvent) => {
    if (enterTimer) {
      clearTimeout(enterTimer)
      enterTimer = null
    }
    leaveTimer = window.setTimeout(() => {
      onLeave(e)
    }, leaveDelay >= 0 ? leaveDelay : delay)
  }

  element.addEventListener('mouseenter', handleMouseEnter)
  element.addEventListener('mouseleave', handleMouseLeave)

  // Returns a disposer to remove the event listeners
  return () => {
    element.removeEventListener('mouseenter', handleMouseEnter)
    element.removeEventListener('mouseleave', handleMouseLeave)
  }
}
